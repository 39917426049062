import Papa from "papaparse";
import React, { useState } from "react";
import Config from "../../../config/Config";
import M from "materialize-css";
import Breadcrumb from "../../components/Breadcrumb";
import { product } from "../../helpers/csvHelper";
import { CSVLink } from "react-csv";

const AddProductFromCSV = () => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploaded, setUploaded] = useState([]);

  const fileChangeHandler = (event) => {
    const files = event.target.files[0];
    if (files) {
      setUploadLoading(true);

      Papa.parse(event.target.files[0], {
        complete: async (results) => {
          let keys = results.data[0];
          // I want to remove some óíúáé, blan spaces, etc
          keys = results.data[0].map((v) =>
            v
              // .toLowerCase()
              .replace(/ /g, "_")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
          let values = results.data.slice(1);
          let objects = values.map((array) => {
            let object = {};
            keys.forEach((key, i) => (object[key] = array[i]));
            return object;
          });
          // Now I call to my API and everything goes ok

          // Get data from array and call the api
          objects.map((item, i) => {
            if (item.name) {
              if (!item.slug) {
                item.slug = item.name
                  .toLowerCase()
                  .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
                  .replace(/\s+/g, "-");
              }

              if (item.images) {
                item.images = item.images.split(",").map((img) => {
                  return img;
                });
              }

              submitHandler(item);
            }
            if (i == objects.length - 1) {
              setUploadLoading(false);
            }
          });
        },
      });
    }
  };

  // Submit Handler
  const submitHandler = (data) => {
    const addData = {
      name: data.name,
      slug: data.slug,
      mrp: data.mrp,
      sellingPrice: data.sellingPrice,
      sku: data.sku,
      maximumOrderQuantity: data.maximumOrderQuantity,
      defaultImage: data.defaultImage,
      images: data.images || [],
      shortDescription: data.shortDescription,
      description: data.description,
      category: data.category,
      occasion: data.occasion,
      tagLine: data.tagLine,
      seoTitle: data.seoTitle,
      seoDescription: data.seoDescription,
      seoTags: data.seoTags,
    };

    fetch(Config.SERVER_URL + "/products", {
      method: "POST",
      body: JSON.stringify(addData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
          }
          setUploaded((old) => {
            return [
              ...old,
              {
                name: result.body.name || "",
                message: result.message || result.errors.message,
              },
            ];
          });
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"PRODUCT"} pageTitle={"Add Product"} />

        {/* Add  Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              //   onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Color Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12 d-flex justify-content-between">
                  <h3 className={"my-3 text-info"}>Upload CSV File</h3>
                  <div className="">
                    <CSVLink
                      className="btn btn-info"
                      headers={product.headers}
                      data={product.dummyData}
                      filename="products.csv"
                    >
                      Download CSV Format
                    </CSVLink>
                  </div>
                </div>

                <div className={"form-group col-md-6"}>
                  <input
                    type="file"
                    onChange={fileChangeHandler}
                    className="form-control"
                    placeholder={"Chocolaty"}
                  />
                </div>
                <div className={"form-group col-md-6"}>
                  {uploadLoading ? (
                    <div className={"bg-white p-3 text-center"}>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-11 mx-auto">
            <div className={"row shadow-sm bg-white py-3"}>
              <div className="col-md-12">
                {uploaded.map((item, index) => {
                  return (
                    <div className="card card-body" key={"item" + index}>
                      {" "}
                      {item.name} {item.message}{" "}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductFromCSV;
