import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBATKQckteiXt05mYvE_a3wedVm1dtyLA8",
  authDomain: "verda-interiors.firebaseapp.com",
  projectId: "verda-interiors",
  storageBucket: "verda-interiors.appspot.com",
  messagingSenderId: "648994521810",
  appId: "1:648994521810:web:e78560d21d32b2e7d2230b",
  measurementId: "G-B3SMNKJ4HF",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
