import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Select from "react-select";
import Breadcrumb from "../../components/Breadcrumb";
import $ from "jquery";

function ProductSizes(props) {
  const { id } = useParams();
  // State Variable
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [productSizes, setProductSizes] = useState([]);
  const [sizeAdded, setSizeAdded] = useState(false);
  const [formData, setFormData] = useState({
    mrp: "",
    sellingPrice: "",
    stock: "",
    maximumOrderQuantity: 2,
  });
  const [updateFormData, setUpdateFormData] = useState({
    mrp: "",
    sellingPrice: "",
    stock: "",
    maximumOrderQuantity: 2,
  });
  const [sizes, setSizes] = useState([]);
  const [sizeDeleting, setSizeDeleting] = useState(false);
  const [sizeDeleted, setSizeDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [sizeUpdating, setSizeUpdating] = useState(false);
  const [sizeUpdated, setSizeUpdated] = useState(false);

  const [inputFields, setInputFields] = useState([
    { mrp: "", sellingPrice: "", stock: "", maximumOrderQuantity: 2 },
  ]);
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { mrp: "", sellingPrice: "", stock: "", maximumOrderQuantity: 2 },
    ]);
  };
  const handleRemoveFields = (index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields.splice(index, 1);
    setInputFields(updatedInputFields);
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoading(true);
    evt.preventDefault();

    inputFields.map((inputField) => {
      const newSize = {
        ...inputField,
        product: id,
      };

      fetch(Config.SERVER_URL + "/productSizes", {
        method: "POST",
        body: JSON.stringify(newSize),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status === 200) {
              M.toast({ html: result.message, classes: "bg-success" });
              $("#addModalCloseButton").click();
              setInputFields([
                {
                  mrp: "",
                  sellingPrice: "",
                  stock: "",
                  maximumOrderQuantity: 2,
                },
              ]);
              setSizeAdded(!sizeAdded);
            } else {
              const errorKeys = Object.keys(result.errors);
              errorKeys.forEach((key) => {
                M.toast({ html: result.errors[key], classes: "bg-danger" });
              });
              M.toast({ html: result.message, classes: "bg-danger" });
            }
            setIsAddLoading(false);
          },
          (error) => {
            setIsAddLoading(false);
            M.toast({ html: error, classes: "bg-danger" });
          }
        );
    });
  };

  // get Sizes
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/sizes?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setSizes(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Product Sizes
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/productSizes?product=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setProductSizes(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [id, sizeAdded, sizeDeleted, sizeUpdated]);

  // Delete Submit Handler
  const deleteSubmitHandler = () => {
    setSizeDeleting(true);

    fetch(`${Config.SERVER_URL}/productSizes/${deleteId}`, {
      method: "DELETE",
      // body: JSON.stringify({deleteId}),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status == 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            setDeleteId("");
            setSizeDeleted(!sizeDeleted);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          $("#closeDeleteSizeModalButton").click();
          setSizeDeleting(false);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setSizeDeleting(false);
        }
      );
  };

  // Update Handler
  const updateHandler = (evt) => {
    evt.preventDefault();
    setSizeUpdating(true);
    const updateProductSize = {
      size: updateFormData?.size?.value,
      mrp: updateFormData?.mrp,
      sellingPrice: updateFormData?.sellingPrice,
      stock: updateFormData?.stock,
      maximumOrderQuantity: updateFormData?.maximumOrderQuantity,
    };
    fetch(`${Config.SERVER_URL}/productSizes/${updateFormData._id}`, {
      method: "PUT",
      body: JSON.stringify(updateProductSize),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setSizeUpdating(false);
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            $("#updateModalCloseButton").click();
            setSizeUpdated(!sizeUpdated);
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setSizeUpdating(false);
        }
      );
  };

  return (
    <>
      <div className="page-wrapper px-0 pt-0">
        <div className={"container-fluid"}>
          {/* Bread crumb and right sidebar toggle */}
          <Breadcrumb title={"PRODUCT SIZE"} pageTitle={"Add Product Size"} />

          {/* End Bread crumb and right sidebar toggle */}

          {/* Product Size List */}
          <div className="row mt-2">
            <div className={"col-md-12 mx-auto"}>
              {/* PRODUCT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h3 className={"my-3 text-info"}>PRODUCT SIZE LIST</h3>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#addSizeModal"
                      >
                        Add Size
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 table-responsive">
                  <table className="table table-striped">
                    <tr>
                      <th>#SN</th>
                      <th>SIZE</th>
                      <th>PRICE</th>
                      <th>STOCK</th>
                      <th>MAX ORDER QTY</th>
                      <th>STATUS</th>
                      <th>ACTION</th>
                    </tr>
                    {productSizes.map((productSize, index) => {
                      return (
                        <tr>
                          <td>{`#${++index}`}</td>
                          <td>{productSize?.size?.name || "N/A"}</td>
                          <td>
                            <p className="mrp">Rs {productSize.mrp}</p>

                            <span className="text-success">
                              Rs {productSize.sellingPrice}
                            </span>
                          </td>
                          <td>{productSize.stock}</td>
                          <td>{productSize.maximumOrderQuantity}</td>
                          <td>{productSize.status ? "Active" : "Disabled"}</td>
                          <td>
                            <button
                              className="btn btn-info"
                              data-toggle="modal"
                              data-target="#updateSizeModal"
                              onClick={(event) => {
                                setUpdateFormData({
                                  _id: productSize._id,
                                  mrp: productSize.mrp,
                                  sellingPrice: productSize.sellingPrice,
                                  stock: productSize.stock,
                                  size: {
                                    label: productSize?.size?.name,
                                    value: productSize?.size?._id,
                                  },
                                  maximumOrderQuantity:
                                    productSize?.maximumOrderQuantity,
                                });
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                            <button
                              className="btn btn-danger"
                              data-toggle="modal"
                              data-target="#deleteSizeModal"
                              onClick={(e) => {
                                setDeleteId(productSize._id);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Product Size Form */}
          <div className="row mt-2">
            <div className={"col-md-12 mx-auto"}>
              {/* PRODUCT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT SIZE</h3>
                </div>

                {/* Product Size Form */}
                <div className="col-md-12">
                  <div className={"row bg-white py-3"}>
                    <div className="col-md-12">
                      <form
                        action=""
                        className="form-horizontal form-material"
                        onSubmit={submitHandler}
                      >
                        <div className="table-responsive overflow-none">
                          <table className="table table-striped">
                            <tr>
                              <th>Size *</th>
                              <th>Product MRP *</th>
                              <th>Selling Price</th>
                              <th>QTY/Stock *</th>
                              <th>Max Order Qty *</th>
                              <th></th>
                            </tr>

                            {inputFields.map((inputField, index) => (
                              <tr>
                                {/* SELECT SIZE */}
                                <td>
                                  <div className={"form-group overflow-none"}>
                                    <Select
                                      options={sizes}
                                      onChange={({ value }) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].size = value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                {/* PRODUCT MRP */}
                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter product mrp"
                                      value={inputField.mrp}
                                      className="form-control border"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].mrp =
                                          e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                {/* PRODUCT SELLING PRICE */}
                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter product selling price"
                                      value={inputField.sellingPrice}
                                      className="form-control"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].sellingPrice =
                                          e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                {/* PRODUCT STOCK */}
                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter product stock"
                                      value={inputField.stock}
                                      className="form-control"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].stock =
                                          e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                {/* MAXIMUM ORDER QUANTITY */}
                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter max order qty"
                                      value={inputField.maximumOrderQuantity}
                                      className="form-control"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[
                                          index
                                        ].maximumOrderQuantity = e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                {/* DELETE BUTTON */}
                                <td>
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveFields(index)}
                                    className="btn btn-danger"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>

                        <div className="col-md-12">
                          <button
                            type="button"
                            onClick={handleAddFields}
                            className="btn btn-info"
                          >
                            Add Field
                          </button>

                          <button
                            type="submit"
                            className="btn btn-success ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Size Model */}
      <div
        className="modal fade"
        id="addSizeModal"
        tabindex="-1"
        aria-labelledby="addSizeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addSizeModalLabel">
                Add Size
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal form-material"
                onSubmit={submitHandler}
                id="addSizeForm"
              >
                {/* SELECT SIZE */}
                <div className={"form-group overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT SIZE
                  </label>
                  <div className="">
                    <Select
                      options={sizes}
                      onChange={({ value }) => {
                        setFormData({ ...formData, size: value });
                      }}
                    />
                  </div>
                </div>

                {/* PRODUCT MRP */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT MRP
                  </label>
                  <input
                    type="number"
                    value={formData.mrp}
                    onChange={(evt) =>
                      setFormData({ ...formData, mrp: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter product mrp"}
                  />
                </div>

                {/* PRODUCT SELLING PRICE */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SELLING PRICE
                  </label>
                  <input
                    type="number"
                    value={formData.sellingPrice}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        sellingPrice: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product selling price"}
                  />
                </div>

                {/* PRODUCT STOCK */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT STOCK
                  </label>
                  <input
                    type="number"
                    value={formData.stock}
                    onChange={(evt) =>
                      setFormData({ ...formData, stock: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter product stock"}
                  />
                </div>

                {/* MAXIMUM ORDER QUANTITY */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAXIMUM ORDER QUANTITY
                  </label>
                  <input
                    type="text"
                    value={formData.maximumOrderQuantity}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        maximumOrderQuantity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"2"}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="addModalCloseButton"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-info"
                form="addSizeForm"
                disabled={isAddLoading}
              >
                {isAddLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading..
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Update Size Model */}
      <div
        className="modal fade"
        id="updateSizeModal"
        tabindex="-1"
        aria-labelledby="updateSizeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateSizeModalLabel">
                Update Size
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal form-material"
                onSubmit={updateHandler}
                id="updateSizeForm"
              >
                {/* SELECT SIZE */}
                {/* <div className={"form-group overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT SIZE
                  </label>
                  <div className="">
                    <Select
                      options={sizes}
                      value={updateFormData?.size}
                      onChange={(value) => {
                        setUpdateFormData({ ...updateFormData, size: value });
                      }}
                    />
                  </div>
                </div> */}

                {/* PRODUCT MRP */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT MRP
                  </label>
                  <input
                    type="number"
                    value={updateFormData.mrp}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        mrp: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product mrp"}
                  />
                </div>

                {/* PRODUCT SELLING PRICE */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SELLING PRICE
                  </label>
                  <input
                    type="number"
                    value={updateFormData.sellingPrice}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        sellingPrice: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product selling price"}
                  />
                </div>

                {/* PRODUCT STOCK */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT STOCK
                  </label>
                  <input
                    type="number"
                    value={updateFormData.stock}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        stock: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product stock"}
                  />
                </div>

                {/* MAXIMUM ORDER QUANTITY */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAXIMUM ORDER QUANTITY
                  </label>
                  <input
                    type="text"
                    value={updateFormData.maximumOrderQuantity}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        maximumOrderQuantity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"2"}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="updateModalCloseButton"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-info"
                form="updateSizeForm"
                disabled={sizeUpdating}
              >
                {sizeUpdating ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading..
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* -- Delete Size Modal -- */}
      <div
        className="modal fade rounded"
        id="deleteSizeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteSizeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded">
            <div className="modal-body text-center">
              <img
                style={{ width: "150px" }}
                className={"img img-fluid"}
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ5R1g82DqzH4itsxpVCofNGWbAzKN_PJDBew&usqp=CAU"
                }
              />
              <h4 className={"text-center mt-2"}>Do You Want to Delete?</h4>

              <div className={"form-group"}>
                <button
                  className="btn btn-danger rounded px-3"
                  type={"submit"}
                  onClick={deleteSubmitHandler}
                >
                  {sizeDeleting ? (
                    <div>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  ) : (
                    <div>
                      <i className="fas fa-trash"></i> Yes
                    </div>
                  )}
                </button>
                <button
                  className="btn btn-secondary rounded ml-2 px-3"
                  data-dismiss="modal"
                  id={"closeDeleteSizeModalButton"}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSizes;
