import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import M from "materialize-css";
import Config from "../../../config/Config";
import { storage } from "../../../firebase/FirebaseConfig";
import Select from "react-select";
import Breadcrumb from "../../components/Breadcrumb";
import { useParams } from "react-router-dom";

export default function EditProject() {
  const history = useHistory();
  // State Variable
  const { id } = useParams();

  const [isAddLoaded, setIsAddLoaded] = useState(true);
  const [defaultImages, setDefaultImages] = useState("");
  const [defaultImgProgress, setDefaultImgProgress] = useState("");

  const [project, setProject] = useState({
    name: "",
    slug: "",
    defaultImage: "",
    images: [],
    shortDescription: "",
    description: "",

    seoTitle: "",
    seoDescription: "",
    seoTags: "",
  });
  const editorRef = useRef(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);

  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [occasions, setOccasions] = useState([]);

  const [selectCat, setSelectCat] = useState([]);
  const [selectSubCat, setSelectSubCat] = useState([]);
  const [selectChildCat, setSelectChildCat] = useState([]);
  const [selectOccasion, setSelectOccasion] = useState([]);

  const titleChangeHandler = (evt) => {
    const value = evt.target.value;
    setProject({
      ...project,
      slug: value
        .toLowerCase()
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .replace(/\s+/g, "-"),
      name: value,
    });
  };

  // Image Change
  const imageChangeHandler = (event, type) => {
    if (type == "default_image") {
      handleUpload(event.target.files[0], "", type);
    } else {
      if (event.target.files && event.target.files.length) {
        [...event.target.files].map((value, index) => {
          handleUpload(value, index);
        });
      }
    }
  };

  // File Delete Handler
  const fileDeleteHandler = (image, index, type) => {
    // Create a reference to the file to delete
    const fileRef = storage.refFromURL(image);
    // Delete the file
    fileRef
      .delete()
      .then(() => {
        // File deleted successfully
        if (type == "default_image") {
          setDefaultImages("");
          setDefaultImgProgress("");
        } else {
          let pImages = [...previewImages];
          pImages.splice(index, 1);

          let pInfos = [...progressInfos];
          pInfos.splice(index, 1);
          setProgressInfos(pInfos);
          setPreviewImages(pImages);
        }
      })
      .catch((error) => {
        // Uh-oh, an error occurred
        M.toast({ html: error, classes: "bg-danger" });
      });
  };

  // Upload Image
  const handleUpload = (image, i, type) => {
    const uploadTask = storage.ref(`projects/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        if (type == "default_image") {
          setDefaultImgProgress(progress);
        } else {
          let arrs = [...progressInfos];
          arrs[i] = progress;
          setProgressInfos((old) => {
            return [...arrs];
          });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("projects")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            console.log(url);
            if (type == "default_image") {
              setDefaultImages(url);
              setProject((old) => {
                return {
                  ...old,
                  defaultImage: url,
                };
              });
            } else {
              setPreviewImages((old) => [...old, url]);
              setProject((old) => {
                return {
                  ...old,
                  images: [...old.images, { url }],
                };
              });
            }
          });
      }
    );
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    const filteredCat = selectCat.map(({ value }) => {
      return value;
    });
    const filteredSubCat = selectSubCat.map(({ value }) => {
      return value;
    });

    const filteredChildCat = selectChildCat.map(({ value }) => {
      return value;
    });

    const addProject = {
      ...project,
      images: previewImages,
      // occasions: filteredOccasion,
      categories: filteredCat,
      subCategories: filteredSubCat,
      childCategories: filteredChildCat,
      _id: undefined,
      id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    };

    fetch(Config.SERVER_URL + "/projects/" + id, {
      method: "PUT",
      body: JSON.stringify(addProject),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Category
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/projectCategories?page=1&limit=1000`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setCategories(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Sub Category
  useEffect(() => {
    let url = `${Config.SERVER_URL}/projectSubCategories?limit=0`;
    if (selectCat.length) {
      let str = "";
      selectCat.forEach(function (item) {
        str += "&categories=" + item.value;
      });
      url += str;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setSubCategories(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [selectCat]);

  // get Child Category
  useEffect(() => {
    let url = `${Config.SERVER_URL}/projectChildCategories?limit=0`;
    if (selectCat.length) {
      let str = "";
      selectCat.forEach(function (item) {
        str += "&categories=" + item.value;
      });
      url += str;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setChildCategories(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [selectCat]);

  // get Records
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/projects/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setProject(result.body);
            setDefaultImages(result?.body?.defaultImage);
            setPreviewImages(result?.body?.images);

            // Set Categories
            if (result?.body?.categories) {
              setSelectCat(
                result?.body?.categories?.map((category) => {
                  return {
                    label: category?.name,
                    value: category?._id,
                  };
                })
              );
            }
            // Set Sub Categories
            if (result?.body?.subCategories) {
              setSelectSubCat(
                result?.body?.subCategories?.map((category) => {
                  return {
                    label: category?.name,
                    value: category?._id,
                  };
                })
              );
            }
            // Set SchildCategories
            if (result?.body?.childCategories) {
              setSelectChildCat(
                result?.body?.childCategories?.map((category) => {
                  return {
                    label: category?.name,
                    value: category?._id,
                  };
                })
              );
            }
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"PROJECTS"} pageTitle={"Add Project"} />

        {/* End Bread crumb and right sidebar toggle */}

        {/* Project Form */}
        <div className="row mt-2">
          <div className={"col-md-12 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* PROJECT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PROJECT DETAILS</h3>
                </div>

                {/* PROJECT Name */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT NAME
                  </label>
                  <input
                    type="text"
                    value={project.name}
                    onChange={titleChangeHandler}
                    className="form-control"
                    placeholder={"Enter project name"}
                  />
                </div>

                {/* Project Slug */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT SLUG
                  </label>
                  <input
                    type="text"
                    value={project.slug}
                    onChange={(evt) =>
                      setProject({ ...project, slug: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter project slug"}
                  />
                </div>

                {/* PROJECT CATEGORIES */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT CATEGORIES
                  </label>
                  <div className="">
                    <Select
                      options={categories}
                      onChange={(value) => {
                        setSelectCat(value);
                      }}
                      value={selectCat}
                      isMulti
                      placeholder="Select project categories"
                    />
                  </div>
                </div>

                {/* PROJECT SUB CATEGORIES */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT SUB CATEGORIES
                  </label>
                  <div className="">
                    <Select
                      options={subCategories}
                      onChange={(value) => {
                        setSelectSubCat(value);
                      }}
                      isMulti
                      value={selectSubCat}
                      placeholder="Select project sub categories"
                    />
                  </div>
                </div>

                {/* PROJECT CHILD CATEGORIES */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT CHILD CATEGORIES
                  </label>
                  <div className="">
                    <Select
                      options={childCategories}
                      onChange={(value) => {
                        setSelectChildCat(value);
                      }}
                      isMulti
                      value={selectChildCat}
                      placeholder="Select project child categories"
                    />
                  </div>
                </div>
              </div>

              {/* PROJECT DESCRIPTION */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PROJECT DESCRIPTION</h3>
                </div>

                {/* SHORT DESCRIPTION */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SHORT DESCRIPTION
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProject({
                        ...project,
                        shortDescription: evt.target.value,
                      })
                    }
                    name="weight"
                    value={project.shortDescription}
                    className="form-control"
                    placeholder={"Enter short Descriptions"}
                  />
                </div>

                {/* LONG DESCRIPTION */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    LONG DESCTRIPTION
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={null}
                    // value={editorValue}
                    onChange={(newValue, editor) => {
                      setProject({
                        ...project,
                        description: editor.getContent(),
                      });
                    }}
                    init={{
                      height: 200,

                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help table image media anchor link",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>

              {/* SEO DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-2"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>SEO DETAILS</h3>
                </div>

                {/* SEO TITLE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SEO TITLE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProject({
                        ...project,
                        seoTitle: evt.target.value,
                      })
                    }
                    value={project.seoTitle}
                    className="form-control"
                    placeholder={"Enter seo title"}
                  />
                </div>

                {/* SEO TAGS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SEO TAGS
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProject({
                        ...project,
                        seoTags: evt.target.value,
                      })
                    }
                    name="weight"
                    value={project.seoTags}
                    className="form-control"
                    placeholder={"Enter tags with comma separated"}
                  />
                </div>

                {/* SEO DESCRIPTION */}
                <div className={"form-group mb-3 col-md-12"}>
                  <label className={"text-dark h6"}>SEO DESCRIPTION</label>
                  <textarea
                    type="text"
                    onChange={(evt) =>
                      setProject({
                        ...project,
                        seoDescription: evt.target.value,
                      })
                    }
                    value={project.seoDescription}
                    className="form-control"
                    placeholder={"Enter seo description"}
                  />
                </div>
              </div>

              {/* PROJECT IMAGES */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PROJECT IMAGES</h3>
                </div>

                {/* PROJECT DEFAULT IMAGES */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT DEFAULT IMAGE
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(evt) => imageChangeHandler(evt, "default_image")}
                    className="form-control"
                  />
                </div>

                <div className="col-md-6">
                  {defaultImages ? (
                    <div className={"form-group"}>
                      <img
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          border: "1px solid #5a5a5a",
                        }}
                        src={defaultImages}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "40%",
                          right: "45%",
                        }}
                        type="button"
                        className="btn bg-light text-danger"
                        title={"Delete Image"}
                        onClick={(evt) =>
                          fileDeleteHandler(defaultImages, "", "default_image")
                        }
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {defaultImgProgress ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${defaultImgProgress}%` }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {defaultImgProgress}%
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* Projects Multiple Images */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PROJECT IMAGES
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={imageChangeHandler}
                    className="form-control"
                  />
                </div>

                {/*Multiple Image Preview */}
                <div className="col-md-12 mb-1">
                  <div className="row">
                    {previewImages.map((img, index) => {
                      return (
                        <div className={"form-group col-md-3"} key={index}>
                          <img
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              border: "1px solid #5a5a5a",
                            }}
                            src={img}
                          />
                          <button
                            style={{
                              position: "absolute",
                              top: "40%",
                              right: "45%",
                            }}
                            type="button"
                            className="btn bg-light text-danger"
                            title={"Delete Image"}
                            onClick={(evt) => fileDeleteHandler(img, index)}
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Multiple image prpgress */}
                <div className="col-md-12 mb-2">
                  <div className="row">
                    {progressInfos.map((info, index) => {
                      return (
                        <div className="col-md-3" key={index}>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${info}%` }}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {info}%
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Submit Button */}
                <div className={"form-group col-md-6"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isAddLoaded ? (
                      <div>
                        <i className="fas fa-plus"></i> Update Project
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
