import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import date from "date-and-time";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";

const DeliveryBoyDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const [dataLoding, setDataLoding] = useState(true);
  const query = new URLSearchParams(history.location.search);

  const tab = query.get("tab");
  const [activeTab, setActiveTab] = useState(tab || "profile");

  const [deliveryAreas, setDeliveryAreas] = useState([]);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);

  const [user, setUser] = useState({
    shippingAddresses: [],
  });

  // get Delivery Boy Details
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/deliveryBoys/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setUser(result.body);
            setDeliveryAreas(result?.body?.deliveryAreas);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setDataLoding(false);
        },
        (error) => {
          setDataLoding(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  const tabClickHandler = (tabName) => {
    setActiveTab(tabName);
    const url = history.location.pathname;
    history.push(`${url}?tab=${tabName}`);
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"DELIVERY BOY"} pageTitle={"Delivery Boy Details"} />
        {/* Details */}

        {!dataLoding ? (
          <div className={"row"}>
            <div className="col-md-12">
              <div className="card shadow-sm border-0">
                <div className="card-body">
                  {/* Tabs */}
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {/* Profile */}
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => {
                          tabClickHandler("profile");
                        }}
                        className={`nav-link ${
                          activeTab == "profile" ? "active" : null
                        }`}
                        id="profile-tab"
                        data-toggle="tab"
                        data-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                      >
                        Profile
                      </button>
                    </li>

                    {/* Delivery Areas */}
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => {
                          tabClickHandler("deliveryArea");
                        }}
                        className={`nav-link ${
                          activeTab == "deliveryArea" ? "active" : null
                        }`}
                        id="deliveryArea-tab"
                        data-toggle="tab"
                        data-target="#deliveryArea"
                        type="button"
                        role="tab"
                        aria-controls="deliveryArea"
                        aria-selected="false"
                      >
                        Delivery Areas
                      </button>
                    </li>
                  </ul>

                  {/* Details */}
                  <div className="tab-content" id="myTabContent">
                    {/* Profile */}
                    <div
                      className={`tab-pane fade show ${
                        activeTab == "profile" ? "active" : null
                      }`}
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          {/* Personal Details */}
                          <div className="card shadow-none border-0">
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="py-3">
                                  <img
                                    style={{
                                      height: "80px",
                                      width: "80px",
                                      borderRadius: "40px",
                                    }}
                                    src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                                    alt=""
                                  />
                                </div>

                                <div className="p-2 ml-3 ">
                                  <h4 className="p-0 m-0">{user.name}</h4>
                                  <p className="p-0 m-0 text-sm">
                                    <span className="badge badge-info">
                                      {user._id}
                                    </span>
                                  </p>
                                  <h5>Email : {user.email} </h5>
                                  <h5>Mobile : {user.mobile} </h5>
                                  <h5>
                                    Account Status:
                                    {user.status ? (
                                      <span className="badge badge-info">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        Disabled
                                      </span>
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Delivery Area */}
                    <div
                      className={`tab-pane fade show ${
                        activeTab == "deliveryArea" ? "active" : null
                      }`}
                      id="deliveryArea"
                      role="tabpanel"
                      aria-labelledby="deliveryArea-tab"
                    >
                      <div className="row">
                        <div className="col-md-12 table-responsive">
                          {/* Subscription Details */}
                          <div className="card shadow-sm border-0 mt-3">
                            {dataLoding ? (
                              <div className={"bg-white p-3 text-center"}>
                                <Spinner />
                              </div>
                            ) : (
                              <div class="card-body">
                                {deliveryAreas.length ? (
                                  <div class="table-responsive">
                                    <table class="table bg-white">
                                      <thead>
                                        <tr>
                                          <th>#ID</th>
                                          <th>NAME</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {deliveryAreas.map((data, index) => {
                                          return (
                                            <tr key={index}>
                                              <td> # {++index} </td>
                                              <td> {data.name} </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="alert alert-danger h6">
                                    Delivery Area Not Assigned.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12 m-auto">
            <div className={"bg-white p-3 text-center"}>
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading..
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryBoyDetails;
