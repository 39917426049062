import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import date from "date-and-time";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";

const EditAdmin = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isUpdateLoaded, setIsUpdateLoaded] = useState(true);
  const [isCouponLoaded, setIsAdminLoaded] = useState(false);

  const [formData, setFormData] = useState({
    // Personal Details
    dashboard: {},
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsUpdateLoaded(false);
    evt.preventDefault();

    fetch(`${Config.SERVER_URL}/admins/${id}`, {
      method: "PUT",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsUpdateLoaded(true);
        },
        (error) => {
          setIsUpdateLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Records
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/admins/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setFormData(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAdminLoaded(true);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsAdminLoaded(true);
        }
      );
  }, []);

  // ClickHandler
  const clickHandler = (evt, parent) => {
    const data = { ...formData };
    data[parent][evt.target.name] = !data[parent][evt.target.name];
    setFormData({ ...formData, ...data });
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"ADMIN"} pageTitle={"Update Admin"} />

        {/* Add Coupon Form */}
        <div className="row">
          {isCouponLoaded ? (
            <div className={"col-md-11 mx-auto"}>
              <form
                onSubmit={submitHandler}
                className="form-horizontal form-material"
              >
                {/* ADMIN DETAILS */}
                <div className={"row shadow-sm bg-white py-3"}>
                  <div className="col-md-12">
                    <h3 className={"my-3 text-info"}>BASIC DETAILS</h3>
                  </div>

                  {/* FULL NAME */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      FULL NAME
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(evt) =>
                        setFormData({ ...formData, name: evt.target.value })
                      }
                      className="form-control"
                      placeholder={"Enter name"}
                    />
                  </div>

                  {/* SELECT USER TYPE */}
                  <div className={"col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      SELECT USER TYPE
                    </label>
                    <div className="d-flex mt-2">
                      <div className="custom-control custom-radio pl-0">
                        <input
                          type="radio"
                          id="userType1"
                          name="userType"
                          value={"SUPER_ADMIN"}
                          onChange={(evt) =>
                            setFormData({
                              ...formData,
                              adminType: evt.target.value,
                            })
                          }
                          checked={
                            formData.adminType == "SUPER_ADMIN" ? true : false
                          }
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" for="userType1">
                          SUPER ADMIN
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="userType2"
                          name="userType"
                          onChange={(evt) =>
                            setFormData({
                              ...formData,
                              adminType: evt.target.value,
                            })
                          }
                          value={"ADMIN"}
                          className="custom-control-input"
                          checked={formData.adminType == "ADMIN" ? true : false}
                        />
                        <label className="custom-control-label" for="userType2">
                          ADMIN
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* EMAIL */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      EMAIL!
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(evt) =>
                        setFormData({ ...formData, email: evt.target.value })
                      }
                      name={"email"}
                      className="form-control"
                      placeholder={"Enter email"}
                    />
                  </div>

                  {/* MOBILE */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      MOBILE!
                    </label>
                    <input
                      type="tel"
                      value={formData.mobile}
                      onChange={(evt) =>
                        setFormData({ ...formData, mobile: evt.target.value })
                      }
                      name={"mobile"}
                      className="form-control"
                      placeholder={"Enter mobile"}
                    />
                  </div>

                  <div className="col-md-12">
                    <h3 className={"my-3 text-info"}>ADMIN RIGHTS</h3>
                  </div>

                  <div className={"form-group col-md-12 mt-3"}>
                    <button
                      className="btn btn-info rounded px-3 py-2"
                      type={"submit"}
                    >
                      {isUpdateLoaded ? (
                        <div>
                          <i className="fas fa-refresh"></i> Update Admin
                        </div>
                      ) : (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading..
                        </div>
                      )}
                    </button>
                  </div>
                </div>

                {/* ADMIN RIGHTS */}
                <div className={"row shadow-sm bg-white py-3 mt-3"}>
                  <div className="col-md-12">
                    <h3 className={"my-3 text-info"}>ADMIN RIGHTS</h3>
                  </div>

                  {/* DASHBOARD */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>DASHBOARD</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Total Users */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.totalUsers ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="totalUsers"
                            name={"totalUsers"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="totalUsers">
                            Total Users
                          </label>
                        </div>
                        {/* Total Customers */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.totalCustomers ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="totalCustomers"
                            name={"totalCustomers"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="totalCustomers">
                            Total Customers
                          </label>
                        </div>
                        {/* Total Orders */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.totalOrders ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="totalOrders"
                            name={"totalOrders"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="totalOrders">
                            Total Orders
                          </label>
                        </div>
                        {/* Today Orders */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.todayOrders ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="todayOrders"
                            name={"todayOrders"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="todayOrders">
                            Today Orders
                          </label>
                        </div>
                        {/* Total Cancelled Orders */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.totalCancelledOrder
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="totalCancelledOrder"
                            name={"totalCancelledOrder"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="totalCancelledOrder">
                            Total Cancelled Orders
                          </label>
                        </div>

                        {/* Total Delivered Orders */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.totalDeleveredOrder
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="totalDeleveredOrder"
                            name={"totalDeleveredOrder"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="totalDeleveredOrder">
                            Total Delivered Orders
                          </label>
                        </div>

                        {/* Total Returned Orders */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.totalReturnedOrder
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="totalReturnedOrder"
                            name={"totalReturnedOrder"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="totalReturnedOrder">
                            Total Returned Orders
                          </label>
                        </div>

                        {/* Top 10 Products */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.topProducts ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="topProducts"
                            name={"topProducts"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="topProducts">
                            Top 10 Products
                          </label>
                        </div>

                        {/* Top 10 Customers */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.dashboard?.topCustomers ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="topCustomers"
                            name={"topCustomers"}
                            onClick={(evt) => clickHandler(evt, "dashboard")}
                          />
                          <label className="" for="topCustomers">
                            Top 10 Customers
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SETUP */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>SETUP</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Setting */}
                        <div className="px-2">
                          <input
                            checked={formData?.setup?.setting ? true : false}
                            type="checkbox"
                            className=""
                            id="setting"
                            name={"setting"}
                            onClick={(evt) => clickHandler(evt, "setup")}
                          />
                          <label className="" for="setting">
                            Setting
                          </label>
                        </div>
                        {/* Main Menu */}
                        <div className="px-2">
                          <input
                            checked={formData?.setup?.mainMenu ? true : false}
                            type="checkbox"
                            className=""
                            id="mainMenu"
                            name={"mainMenu"}
                            onClick={(evt) => clickHandler(evt, "setup")}
                          />
                          <label className="" for="mainMenu">
                            Main Menu
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* PINCODE */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>PINCODE</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Pincode List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.pincode?.pincodeList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="pincodeList"
                            name={"pincodeList"}
                            onClick={(evt) => clickHandler(evt, "pincode")}
                          />
                          <label className="" for="pincodeList">
                            Pincode List
                          </label>
                        </div>
                        {/* Add Pincode */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.pincode?.createPincode ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createPincode"
                            name={"createPincode"}
                            onClick={(evt) => clickHandler(evt, "pincode")}
                          />
                          <label className="" for="createPincode">
                            Add Pincode
                          </label>
                        </div>
                        {/* Edit Pincode */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.pincode?.editPincode ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editPincode"
                            name={"editPincode"}
                            onClick={(evt) => clickHandler(evt, "pincode")}
                          />
                          <label className="" for="editPincode">
                            Edit Pincode
                          </label>
                        </div>
                        {/* Delete Pincode */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.pincode?.deletePincode ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deletePincode"
                            name={"deletePincode"}
                            onClick={(evt) => clickHandler(evt, "pincode")}
                          />
                          <label className="" for="deletePincode">
                            Delete Pincode
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* COUPON */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>COUPON</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Coupon List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.coupon?.couponList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="couponList"
                            name={"couponList"}
                            onClick={(evt) => clickHandler(evt, "coupon")}
                          />
                          <label className="" for="couponList">
                            Coupon List
                          </label>
                        </div>
                        {/* Add Coupon */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.coupon?.createCoupon ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createCoupon"
                            name={"createCoupon"}
                            onClick={(evt) => clickHandler(evt, "coupon")}
                          />
                          <label className="" for="createCoupon">
                            Add Coupon
                          </label>
                        </div>
                        {/* Edit Coupon */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.coupon?.editCoupon ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editCoupon"
                            name={"editCoupon"}
                            onClick={(evt) => clickHandler(evt, "coupon")}
                          />
                          <label className="" for="editCoupon">
                            Edit Coupon
                          </label>
                        </div>
                        {/* Delete Coupon */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.coupon?.deleteCoupon ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteCoupon"
                            name={"deleteCoupon"}
                            onClick={(evt) => clickHandler(evt, "coupon")}
                          />
                          <label className="" for="deleteCoupon">
                            Delete Coupon
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* COLOR */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>COLOR</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Color List */}
                        <div className="px-2">
                          <input
                            checked={formData?.color?.colorList ? true : false}
                            type="checkbox"
                            className=""
                            id="colorList"
                            name={"colorList"}
                            onClick={(evt) => clickHandler(evt, "color")}
                          />
                          <label className="" for="colorList">
                            Color List
                          </label>
                        </div>
                        {/* Add Color */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.color?.createColor ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createColor"
                            name={"createColor"}
                            onClick={(evt) => clickHandler(evt, "color")}
                          />
                          <label className="" for="createColor">
                            Add Color
                          </label>
                        </div>
                        {/* Edit Color */}
                        <div className="px-2">
                          <input
                            checked={formData?.color?.editColor ? true : false}
                            type="checkbox"
                            className=""
                            id="editColor"
                            name={"editColor"}
                            onClick={(evt) => clickHandler(evt, "color")}
                          />
                          <label className="" for="editColor">
                            Edit Color
                          </label>
                        </div>
                        {/* Delete Color */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.color?.deleteColor ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteColor"
                            name={"deleteColor"}
                            onClick={(evt) => clickHandler(evt, "color")}
                          />
                          <label className="" for="deleteColor">
                            Delete Color
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SIZE */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>SIZE</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Size List */}
                        <div className="px-2">
                          <input
                            checked={formData?.size?.sizeList ? true : false}
                            type="checkbox"
                            className=""
                            id="sizeList"
                            name={"sizeList"}
                            onClick={(evt) => clickHandler(evt, "size")}
                          />
                          <label className="" for="sizeList">
                            Size List
                          </label>
                        </div>
                        {/* Add Size */}
                        <div className="px-2">
                          <input
                            checked={formData?.size?.createSize ? true : false}
                            type="checkbox"
                            className=""
                            id="createSize"
                            name={"createSize"}
                            onClick={(evt) => clickHandler(evt, "size")}
                          />
                          <label className="" for="createSize">
                            Add Size
                          </label>
                        </div>
                        {/* Edit Size */}
                        <div className="px-2">
                          <input
                            checked={formData?.size?.editSize ? true : false}
                            type="checkbox"
                            className=""
                            id="editSize"
                            name={"editSize"}
                            onClick={(evt) => clickHandler(evt, "size")}
                          />
                          <label className="" for="editSize">
                            Edit Size
                          </label>
                        </div>
                        {/* Delete Size */}
                        <div className="px-2">
                          <input
                            checked={formData?.size?.deleteSize ? true : false}
                            type="checkbox"
                            className=""
                            id="deleteSize"
                            name={"deleteSize"}
                            onClick={(evt) => clickHandler(evt, "size")}
                          />
                          <label className="" for="deleteSize">
                            Delete Size
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ADMIN */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>ADMIN</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Admin List */}
                        <div className="px-2">
                          <input
                            checked={formData?.admin?.adminList ? true : false}
                            type="checkbox"
                            className=""
                            id="adminList"
                            name={"adminList"}
                            onClick={(evt) => clickHandler(evt, "admin")}
                          />
                          <label className="" for="adminList">
                            Admin List
                          </label>
                        </div>
                        {/* Add Admin */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.admin?.createAdmin ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createAdmin"
                            name={"createAdmin"}
                            onClick={(evt) => clickHandler(evt, "admin")}
                          />
                          <label className="" for="createAdmin">
                            Add Admin
                          </label>
                        </div>
                        {/* Edit Admin */}
                        <div className="px-2">
                          <input
                            checked={formData?.admin?.editAdmin ? true : false}
                            type="checkbox"
                            className=""
                            id="editAdmin"
                            name={"editAdmin"}
                            onClick={(evt) => clickHandler(evt, "admin")}
                          />
                          <label className="" for="editAdmin">
                            Edit Admin
                          </label>
                        </div>
                        {/* Delete Admin */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.admin?.deleteAdmin ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteAdmin"
                            name={"deleteAdmin"}
                            onClick={(evt) => clickHandler(evt, "admin")}
                          />
                          <label className="" for="deleteAdmin">
                            Delete Admin
                          </label>
                        </div>
                        {/* Admin Rights */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.admin?.adminRights ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="adminRights"
                            name={"adminRights"}
                            onClick={(evt) => clickHandler(evt, "admin")}
                          />
                          <label className="" for="adminRights">
                            Admin Rights
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* CUSTOMER */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>CUSTOMER</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Customer List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.customer?.customeList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="customeList"
                            name={"customeList"}
                            onClick={(evt) => clickHandler(evt, "customer")}
                          />
                          <label className="" for="customeList">
                            Customer List
                          </label>
                        </div>
                        {/* Add Customer */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.customer?.createCustomer ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createCustomer"
                            name={"createCustomer"}
                            onClick={(evt) => clickHandler(evt, "customer")}
                          />
                          <label className="" for="createCustomer">
                            Add Customer
                          </label>
                        </div>
                        {/* Edit Customer */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.customer?.editCustomer ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editCustomer"
                            name={"editCustomer"}
                            onClick={(evt) => clickHandler(evt, "customer")}
                          />
                          <label className="" for="editCustomer">
                            Edit Customer
                          </label>
                        </div>
                        {/* Delete Customer */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.customer?.deleteCustomer ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteCustomer"
                            name={"deleteCustomer"}
                            onClick={(evt) => clickHandler(evt, "customer")}
                          />
                          <label className="" for="deleteCustomer">
                            Delete Customer
                          </label>
                        </div>
                        {/* Customer Details */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.customer?.customerDetails ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="customerDetails"
                            name={"customerDetails"}
                            onClick={(evt) => clickHandler(evt, "customer")}
                          />
                          <label className="" for="customerDetails">
                            Customer Details
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* NEWSLETTER */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>NEWSLETTER</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Newsletter List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.newsletter?.newsletterList
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="newsletterList"
                            name={"newsletterList"}
                            onClick={(evt) => clickHandler(evt, "newsletter")}
                          />
                          <label className="" for="newsletterList">
                            Newsletter List
                          </label>
                        </div>
                        {/* Add Newsletter */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.newsletter?.createNewsletter
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="createNewsletter"
                            name={"createNewsletter"}
                            onClick={(evt) => clickHandler(evt, "newsletter")}
                          />
                          <label className="" for="createNewsletter">
                            Add Newsletter
                          </label>
                        </div>
                        {/* Edit Newsletter */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.newsletter?.editNewsletter
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="editNewsletter"
                            name={"editNewsletter"}
                            onClick={(evt) => clickHandler(evt, "newsletter")}
                          />
                          <label className="" for="editNewsletter">
                            Edit Newsletter
                          </label>
                        </div>
                        {/* Delete Newsletter */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.newsletter?.deleteNewsletter
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteNewsletter"
                            name={"deleteNewsletter"}
                            onClick={(evt) => clickHandler(evt, "newsletter")}
                          />
                          <label className="" for="deleteNewsletter">
                            Delete Newsletter
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* INQUIRY */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>INQUIRY</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Inquiry List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.inquiry?.inquiryList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="inquiryList"
                            name={"inquiryList"}
                            onClick={(evt) => clickHandler(evt, "inquiry")}
                          />
                          <label className="" for="inquiryList">
                            Inquiry List
                          </label>
                        </div>
                        {/* Add Inquiry */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.inquiry?.createInquiry ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createInquiry"
                            name={"createInquiry"}
                            onClick={(evt) => clickHandler(evt, "inquiry")}
                          />
                          <label className="" for="createInquiry">
                            Add Inquiry
                          </label>
                        </div>
                        {/* Edit Inquiry */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.inquiry?.editInquiry ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editInquiry"
                            name={"editInquiry"}
                            onClick={(evt) => clickHandler(evt, "inquiry")}
                          />
                          <label className="" for="editInquiry">
                            Edit Inquiry
                          </label>
                        </div>
                        {/* Delete Inquiry */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.inquiry?.deleteInquiry ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteInquiry"
                            name={"deleteInquiry"}
                            onClick={(evt) => clickHandler(evt, "inquiry")}
                          />
                          <label className="" for="deleteInquiry">
                            Delete Inquiry
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TESTIMONAIL */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>TESTIMONAIL</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Testimonial List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.testimonial?.testimonialList
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="testimonialList"
                            name={"testimonialList"}
                            onClick={(evt) => clickHandler(evt, "testimonial")}
                          />
                          <label className="" for="testimonialList">
                            Testimonial List
                          </label>
                        </div>
                        {/* Add Testimonial */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.testimonial?.createTestimonial
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="createTestimonial"
                            name={"createTestimonial"}
                            onClick={(evt) => clickHandler(evt, "testimonial")}
                          />
                          <label className="" for="createTestimonial">
                            Add Testimonial
                          </label>
                        </div>
                        {/* Edit Testimonial */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.testimonial?.editTestimonial
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="editTestimonial"
                            name={"editTestimonial"}
                            onClick={(evt) => clickHandler(evt, "testimonial")}
                          />
                          <label className="" for="editTestimonial">
                            Edit Testimonial
                          </label>
                        </div>
                        {/* Delete Testimonial */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.testimonial?.deleteTestimonial
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteTestimonial"
                            name={"deleteTestimonial"}
                            onClick={(evt) => clickHandler(evt, "testimonial")}
                          />
                          <label className="" for="deleteTestimonial">
                            Delete Testimonial
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* CATEGORY */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>CATEGORY</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Category List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.category?.categoryList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="categoryList"
                            name={"categoryList"}
                            onClick={(evt) => clickHandler(evt, "category")}
                          />
                          <label className="" for="categoryList">
                            Category List
                          </label>
                        </div>
                        {/* Add Category */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.category?.createCategory ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createCategory"
                            name={"createCategory"}
                            onClick={(evt) => clickHandler(evt, "category")}
                          />
                          <label className="" for="createCategory">
                            Add Category
                          </label>
                        </div>
                        {/* Edit Category */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.category?.editCategory ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editCategory"
                            name={"editCategory"}
                            onClick={(evt) => clickHandler(evt, "category")}
                          />
                          <label className="" for="editCategory">
                            Edit Category
                          </label>
                        </div>
                        {/* Delete Category */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.category?.deleteCategory ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteCategory"
                            name={"deleteCategory"}
                            onClick={(evt) => clickHandler(evt, "category")}
                          />
                          <label className="" for="deleteCategory">
                            Delete Category
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SUB CATEGORY */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>SUB CATEGORY</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Sub Category List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.subCategory?.subCategoryList
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="subCategoryList"
                            name={"subCategoryList"}
                            onClick={(evt) => clickHandler(evt, "subCategory")}
                          />
                          <label className="" for="subCategoryList">
                            Sub Category List
                          </label>
                        </div>
                        {/* Add Sub Category */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.subCategory?.createSubCategory
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="createSubCategory"
                            name={"createSubCategory"}
                            onClick={(evt) => clickHandler(evt, "subCategory")}
                          />
                          <label className="" for="createSubCategory">
                            Add Sub Category
                          </label>
                        </div>
                        {/* Edit Sub Category */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.subCategory?.editSubCategory
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="editSubCategory"
                            name={"editSubCategory"}
                            onClick={(evt) => clickHandler(evt, "subCategory")}
                          />
                          <label className="" for="editSubCategory">
                            Edit Sub Category
                          </label>
                        </div>
                        {/* Delete Sub Category */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.subCategory?.deleteSubCategory
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteSubCategory"
                            name={"deleteSubCategory"}
                            onClick={(evt) => clickHandler(evt, "subCategory")}
                          />
                          <label className="" for="deleteSubCategory">
                            Delete Sub Category
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* PRODUCT */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>PRODUCT</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Product List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.product?.productList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="productList"
                            name={"productList"}
                            onClick={(evt) => clickHandler(evt, "product")}
                          />
                          <label className="" for="productList">
                            Product List
                          </label>
                        </div>
                        {/* Add Product */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.product?.createProduct ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createProduct"
                            name={"createProduct"}
                            onClick={(evt) => clickHandler(evt, "product")}
                          />
                          <label className="" for="createProduct">
                            Add Product
                          </label>
                        </div>
                        {/* Edit Product */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.product?.editProduct ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editProduct"
                            name={"editProduct"}
                            onClick={(evt) => clickHandler(evt, "product")}
                          />
                          <label className="" for="editProduct">
                            Edit Product
                          </label>
                        </div>
                        {/* Delete Product */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.product?.deleteProduct ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteProduct"
                            name={"deleteProduct"}
                            onClick={(evt) => clickHandler(evt, "product")}
                          />
                          <label className="" for="deleteProduct">
                            Delete Product
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* RATING & REVIEWS */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>RATING & REVIEWS</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Review List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.review?.reviewList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="reviewList"
                            name={"reviewList"}
                            onClick={(evt) => clickHandler(evt, "review")}
                          />
                          <label className="" for="reviewList">
                            Review List
                          </label>
                        </div>
                        {/* Add Review */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.review?.createReview ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createReview"
                            name={"createReview"}
                            onClick={(evt) => clickHandler(evt, "review")}
                          />
                          <label className="" for="createReview">
                            Add Review
                          </label>
                        </div>
                        {/* Edit Review */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.review?.editReview ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="editReview"
                            name={"editReview"}
                            onClick={(evt) => clickHandler(evt, "review")}
                          />
                          <label className="" for="editReview">
                            Edit Review
                          </label>
                        </div>
                        {/* Delete Review */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.review?.deleteReview ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteReview"
                            name={"deleteReview"}
                            onClick={(evt) => clickHandler(evt, "review")}
                          />
                          <label className="" for="deleteReview">
                            Delete Review
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ORDER */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>ORDER</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Order List */}
                        <div className="px-2">
                          <input
                            checked={formData?.order?.orderList ? true : false}
                            type="checkbox"
                            className=""
                            id="orderList"
                            name={"orderList"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="orderList">
                            Order List
                          </label>
                        </div>
                        {/* Add Order */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.order?.createOrder ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="createOrder"
                            name={"createOrder"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="createOrder">
                            Add Order
                          </label>
                        </div>
                        {/* Edit Order */}
                        <div className="px-2">
                          <input
                            checked={formData?.order?.editOrder ? true : false}
                            type="checkbox"
                            className=""
                            id="editOrder"
                            name={"editOrder"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="editOrder">
                            Edit Order
                          </label>
                        </div>
                        {/* Delete Order */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.order?.deleteOrder ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteOrder"
                            name={"deleteOrder"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="deleteOrder">
                            Delete Order
                          </label>
                        </div>
                        {/* Order Details */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.order?.orderDetails ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="orderDetails"
                            name={"orderDetails"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="orderDetails">
                            Order Details
                          </label>
                        </div>
                        {/* Order History List */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.order?.orderHistoryList ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="orderHistoryList"
                            name={"orderHistoryList"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="orderHistoryList">
                            Order History List
                          </label>
                        </div>
                        {/* Delete Order History */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.order?.deleteOrderHistory ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="deleteOrderHistory"
                            name={"deleteOrderHistory"}
                            onClick={(evt) => clickHandler(evt, "order")}
                          />
                          <label className="" for="deleteOrderHistory">
                            Delete Order History
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* WEB CONTENTS */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>WEB CONTENTS</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* About Us */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.webContent?.aboutUs ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="aboutUs"
                            name={"aboutUs"}
                            onClick={(evt) => clickHandler(evt, "webContent")}
                          />
                          <label className="" for="aboutUs">
                            About Us
                          </label>
                        </div>
                        {/* Contact Us */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.webContent?.contactUs ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="contactUs"
                            name={"contactUs"}
                            onClick={(evt) => clickHandler(evt, "webContent")}
                          />
                          <label className="" for="contactUs">
                            Contact Us
                          </label>
                        </div>
                        {/* Terms & Conditions */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.webContent?.termsAndConditions
                                ? true
                                : false
                            }
                            type="checkbox"
                            className=""
                            id="termsAndConditions"
                            name={"termsAndConditions"}
                            onClick={(evt) => clickHandler(evt, "webContent")}
                          />
                          <label className="" for="termsAndConditions">
                            Terms & Conditions
                          </label>
                        </div>
                        {/* Privacy & Policy */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.webContent?.privacyPolicy ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="privacyPolicy"
                            name={"privacyPolicy"}
                            onClick={(evt) => clickHandler(evt, "webContent")}
                          />
                          <label className="" for="privacyPolicy">
                            Privacy & Policy
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* REPORT */}
                  <div className={"col-md-3"}>
                    <div className={"card mb-0 mt-2 border-0 rounded-0"}>
                      <div class="card-header bg-theme text-white text-uppercase">
                        <h4 className={"text-white"}>REPORT</h4>
                      </div>
                      <div
                        className={"card-body pb-0 pt-2 px-0"}
                        // style={{ height: "285px", overflowY: "scroll" }}
                      >
                        {/* Top 10 Customers */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.report?.topCustomers ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="topCustomersReport"
                            name={"topCustomers"}
                            onClick={(evt) => clickHandler(evt, "report")}
                          />
                          <label className="" for="topCustomersReport">
                            Top 10 Customers
                          </label>
                        </div>
                        {/* Top 10 Products */}
                        <div className="px-2">
                          <input
                            checked={
                              formData?.report?.topProducts ? true : false
                            }
                            type="checkbox"
                            className=""
                            id="topProductsReport"
                            name={"topProducts"}
                            onClick={(evt) => clickHandler(evt, "report")}
                          />
                          <label className="" for="topProductsReport">
                            Top 10 Products
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="col-md-11 mx-auto text-center bg-white py-5">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
