import React, { useState, useEffect, useMemo } from "react";
import M from "materialize-css";
import { Link, useHistory, useParams } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

// Component Function
const AssignAreas = (props) => {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalRecords: 0,
    totalPages: 0,
  });
  const history = useHistory();
  const { id } = useParams();

  const [isAllAreasLoaded, setIsAllAreasLoaded] = useState(false);
  const [allAreas, setAllAreas] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const selectHandler = (areaId) => {
    if (formData.length) {
      const isExist = formData.some((value) => {
        return value == areaId;
      });

      if (isExist) {
        const filteredArr = formData.filter((value) => {
          return value != areaId;
        });
        setFormData([...filteredArr]);
      } else {
        setFormData([...formData, areaId]);
      }
    } else {
      setFormData([areaId]);
    }
  };

  // Get supervisor details
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${Config.SERVER_URL}/supervisors/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
          },
        });
        const result = await response.json();
        if (result.status == 200) {
          const areasArray = result?.body?.deliveryAreas.map((data) => {
            return data._id;
          });
          setFormData([...areasArray]);
        } else {
          M.toast({ html: result.message, classes: "bg-danger" });
        }
      } catch (error) {
        M.toast({ html: error, classes: "bg-danger" });
      }
    };

    getData();
  }, []);

  // Submit Handler
  const submitHandler = (evt) => {
    evt.preventDefault();

    setUpdateLoading(true);

    fetch(`${Config.SERVER_URL}/supervisors/${id}`, {
      method: "PUT",
      body: JSON.stringify({ deliveryAreas: formData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setUpdateLoading(false);
        },
        (error) => {
          setUpdateLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // Get Areas
  useEffect(() => {
    let url = `${Config.SERVER_URL}/areas?page=${pagination.page}&limit=${pagination.limit}`;
    if (searchQuery) url += `&searchQuery=${searchQuery}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAllAreasLoaded(true);
          if (result.status === 200) {
            setAllAreas(result.body || []);
            setPagination({
              ...pagination,
              page: result.page,
              totalPages: result.totalPages,
              totalRecords: result.totalRecords,
            });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsAllAreasLoaded(true);
        }
      );
  }, [pagination.page, pagination.limit, searchQuery]);

  // Return function
  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb
          title={"DELIVERY AREA"}
          pageTitle={"Assign Delivery Areas"}
        />
        {/* End Bread crumb and right sidebar toggle */}
        {/* Assign Pincode Form */}
        <div className="row mt-2">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* AREA DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-2"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>AREA DETAILS</h3>
                </div>

                <div className={"col-md-12 px-0"}>
                  {/* Heading */}
                  <div className={"card mb-0 mt-2 border-0 rounded"}>
                    <div className={"card-body pb-0 pt-2 d-flex"}>
                      <div>
                        <h4 className="float-left mt-2 mr-2">Search: </h4>
                      </div>
                      <div className="border px-2">
                        <input
                          type="search"
                          onChange={(evt) => {
                            setSearchQuery(evt.target.value);
                          }}
                          placeholder="Enter area name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Data */}
                  {isAllAreasLoaded ? (
                    <div className="card border-0 rounded m-0 py-1">
                      {allAreas?.length ? (
                        <div className="card-body py-0">
                          <div className="table-responsive">
                            <table
                              className={
                                "table table-bordered table-striped my-0"
                              }
                            >
                              <thead>
                                <tr>
                                  <th>SN</th>
                                  <th>NAME</th>
                                  <th>PINCODE</th>
                                  <th className="text-center">ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allAreas.map((area, index) => {
                                  return (
                                    <tr key={area.id}>
                                      <td>{index + 1}</td>
                                      <td>{area.name}</td>
                                      <td>{area?.pincode?.pincode}</td>

                                      <td className="text-center">
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={formData.some((value) => {
                                              return value == area._id;
                                            })}
                                            id={`area-${index}`}
                                            onChange={(evt) =>
                                              selectHandler(area._id)
                                            }
                                          />

                                          <label
                                            className="form-check-label"
                                            htmlFor={`area-${index}`}
                                          ></label>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            {/* Pagination */}
                            <Pagination
                              pagination={pagination}
                              setPagination={setPagination}
                              tableName="table-to-xls"
                              csvFileName="pincodes"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            "alert alert-danger mx-3 rounded border-0 py-2"
                          }
                        >
                          No Data Available
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={"bg-white p-3 text-center"}>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  )}
                </div>

                <div className={"form-group col-md-12"}>
                  <button
                    disabled={updateLoading}
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {updateLoading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <i className="fas fa-plus"></i> Update
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignAreas;
